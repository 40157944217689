import React,{ Component } from 'react';
import {
    Grid,
    Icon,
    Segment,
    Sidebar,
  } from 'semantic-ui-react'
  import './SideBar.css';

  interface SideBarProps {
    headerHTML: JSX.Element
    summaryHTML: JSX.Element
    educationHTML: JSX.Element
    skillsHTML: JSX.Element
    experienceHTML: JSX.Element
    academicHTML: JSX.Element
    sidebarOpen:(sideBarOpen: boolean) => void
    // Define your props here
  }
  
  interface SideBarState {
    visible: boolean;
    headerVisible: boolean;
    summaryVisible: boolean;
    educationVisible: boolean;
    skillsVisible: boolean;
    experienceVisible: boolean;
    academicVisible: boolean;

  }

  class SideBar extends Component<SideBarProps, SideBarState>  {
    constructor(props:SideBarProps) {
        super(props);
        this.state = {
          visible: false,
          headerVisible: false,
          summaryVisible: false,
          educationVisible: false,
          skillsVisible: false,
          experienceVisible: false,
          academicVisible: false
        };
    }

    onClick = () => {
        if (this.state.visible) {
            this.setState((prevState) => ({
                visible: !prevState.visible,
                headerVisible: false,
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false
                }),()=>this.props.sidebarOpen(this.state.visible));
        } else {
            this.setState((prevState) => ({
                visible: !prevState.visible
                }),()=>this.props.sidebarOpen(this.state.visible));
        }
    };

    onClickSummary = () => {
        if (this.state.summaryVisible) {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
                }));
        } else {
            this.setState((prevState) => ({
                summaryVisible: true,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
            }));
        }

    };

    onClickEducation = () => {
        if (this.state.educationVisible) {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
                }));
        } else {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: true,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
            }));
        }
    };

    onClickSkills = () => {
        if (this.state.skillsVisible) {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
                }));
        } else {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: true,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
            }));
        }
    };

    onClickExperience = () => {
        if (this.state.experienceVisible) {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
                }));
        } else {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: true,
                academicVisible: false,
                headerVisible: false,
            }));
        }
    };

    onClickAcademic = () => {
        if (this.state.academicVisible) {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
                }));
        } else {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: true,
                headerVisible: false,
            }));
        }
    };

    onClickConnect = () => {
        if (this.state.headerVisible) {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: false,
                }));
        } else {
            this.setState((prevState) => ({
                summaryVisible: false,
                educationVisible: false,
                skillsVisible: false,
                experienceVisible: false,
                academicVisible: false,
                headerVisible: true,
            }));
        }
    };

    render():JSX.Element {
        const iconNameCaret = !this.state.visible ? "caret down" : "caret up"
        const headerClass: string = this.state.headerVisible ? "" : "hide"
        const summaryClass: string = this.state.visible ? "" : "hide"
        const educationClass: string = this.state.educationVisible ? "" : "hide"
        const skillsClass: string = this.state.skillsVisible ? "" : "hide"
        const experienceClass: string = this.state.experienceVisible ? "" : "hide"
        const academicClass: string = this.state.academicVisible ? "" : "hide"
        const headerSelected: string = this.state.headerVisible ? "selected" : ""
        const summarySelected: string = this.state.visible ? "selected" : ""
        const educationSelected: string = this.state.educationVisible ? "selected" : ""
        const skillsSelected: string = this.state.skillsVisible ? "selected" : ""
        const experienceSelected: string = this.state.experienceVisible ? "selected" : ""
        const academicSelected: string = this.state.academicVisible ? "selected" : ""
        const shiftCaretClass: string = this.state.visible ? "shift-caret" : ""
        // console.log("headerclass:"+headerClass)
        // console.log("headerclass:"+headerClass === "hide" ? "2" : "1")
        return (
        <div className='sidebar'>
            <div className={`caret-container ${shiftCaretClass}`}>
                <Icon 
                    name={iconNameCaret}
                    size="big" 
                    onClick={this.onClick}
                    className="icon-class"
                    title="Click here to explore"
                />

            </div>

            <Sidebar
                as={Segment}
                animation='scale down'
                icon='caret right'
                direction='top'
                inverted
                horizontal
                visible={this.state.visible}
                width='wide'
                >
                    <Grid textAlign='center'>
                        <Grid.Row columns={6}>
                        
                            <Grid.Column>
                                <div className={`icon-div ${summarySelected}`} onClick={this.onClickSummary}>
                                    <Icon name='home'/>
                                    Summary 
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className={`icon-div ${educationSelected}`} onClick={this.onClickEducation}>
                                    <Icon name='university'/>
                                    Education
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className={`icon-div ${skillsSelected}`} onClick={this.onClickSkills}>
                                    <Icon name='lightbulb outline'/>
                                    Skills
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className={`icon-div ${experienceSelected}`} onClick={this.onClickExperience}>
                                    <Icon name='suitcase'/>
                                    Experience
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className={`icon-div ${academicSelected}`} onClick={this.onClickAcademic}>
                                    <Icon name='graduation cap'/>
                                    Academic
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className={`icon-div ${headerSelected}`} onClick={this.onClickConnect}>
                                    <Icon name='address book'/>
                                    Connect
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
            </Sidebar>

            <Sidebar.Pusher>
                <Grid columns={2} className="sidebar-open">
                    <Grid.Column width={4}>
                        <Segment basic>
                        <div className={summaryClass}>
                            {this.props.summaryHTML}
                        </div>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        {headerClass !== 'hide' && (
                        <Segment basic>
                            <div className={headerClass}>
                            {this.props.headerHTML}
                            </div>
                        </Segment>
                        )}

                        {educationClass !== 'hide' && (
                        <Segment basic>
                            <div className={educationClass}>
                            {this.props.educationHTML}
                            </div>
                        </Segment>
                        )}

                        {skillsClass !== 'hide' && (
                        <Segment basic>
                            <div className={skillsClass}>
                            {this.props.skillsHTML}
                            </div>
                        </Segment>
                        )}

                        {experienceClass !== 'hide' && (
                        <Segment basic>
                            <div className={experienceClass}>
                            {this.props.experienceHTML}
                            </div>
                        </Segment>
                        )}

                        {academicClass !== 'hide' && (
                        <Segment basic>
                            <div className={academicClass}>
                            {this.props.academicHTML}
                            </div>
                        </Segment>
                        )}
                    </Grid.Column>
                </Grid>
            </Sidebar.Pusher>
        </div>
        )
    }
  }

  export default SideBar
